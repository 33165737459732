<template>
  <div>
    <div style="height: 30px;margin: 25px 0;display: flex;align-items: center">
      <el-breadcrumb class="bread" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breaditem"><span style="color: #909399">购物车</span></el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="selectdiv">
      <el-input class="family" size="small" v-model.trim="query.name" placeholder="供货商名称"
                @keyup.enter.native="selectCart" clearable maxlength="20"
                style="width: 200px !important;margin-right: 10px">
      </el-input>
      <el-input class="family" size="small" v-model.trim="query.value"
                @keyup.enter.native="selectCart" clearable maxlength="20"
                style="width: 300px !important;margin-right: 10px">
        <el-select class="family" size="small" slot="prepend" v-model="query.type"
                   @change="typeChange" style="width: 75px">
          <el-option value="isbn" label="书号"
                     style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
          <el-option value="title" label="书名"
                     style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
        </el-select>
      </el-input>
      <el-button type="primary" icon="el-icon-search" size="small" @click="selectCart">查询</el-button>
      <div style="width: 404px;display: flex;justify-content: flex-end;align-items: center">
        <el-popover placement="bottom" v-model="visibleClr">
          <div style="display: flex;justify-content: center;align-items: center">
            <span class="spanPopo">确定清空？</span>
            <el-input class="family" size="mini" v-model.trim="clearCheck"
                      placeholder="防误操作，输入1234" maxlength="4"
                      @keyup.enter.native="clrCart"
                      style="width: 170px !important">
            </el-input>
            <el-button class="buttonPopo" size="mini" type="success" @click="clrCart"
                       icon="el-icon-check" :loading="loadingClr"/>
          </div>
          <el-button slot="reference" type="danger" size="small" style="margin-right: 10px"
                     icon="el-icon-delete" :loading="loadingClr" @click="initClearCheck">清空
          </el-button>
        </el-popover>
        <el-button type="warning" icon="el-icon-upload2" size="small"
                   @click="openUpload">上传
        </el-button>
        <el-button type="primary" icon="el-icon-download" size="small"
                   @click="downloadCart" :loading="loadingDlo">下载
        </el-button>
      </div>
    </div>
    <div class="selectdiv" style="margin-top: 30px;margin-bottom: 47px">
      <el-popover placement="bottom" v-model="visibleAdd">
        <div style="display: flex;justify-content: center;align-items: center">
          <span class="spanPopo">确定生成？</span>
          <el-input class="family" size="mini" v-model.trim="sendCheck"
                    placeholder="防误操作，输入1234" maxlength="4"
                    @keyup.enter.native="addOrder"
                    style="width: 170px !important">
          </el-input>
          <el-button class="buttonPopo" size="mini" type="success" @click="addOrder"
                     icon="el-icon-check" :loading="loadingAdd"/>
        </div>
        <el-button slot="reference" type="success" size="small"
                   icon="el-icon-check" :loading="loadingAdd" @click="initSendCheck">生成订单
        </el-button>
      </el-popover>
      <el-checkbox size="small" v-model="onlyChecked"
                   style="margin-left: 30px;margin-right: 0" label="仅生成选中"/>
      <el-checkbox size="small" v-model="ignoreNum"
                   style="margin-left: 30px;margin-right: 0" label="忽略库存不足"/>
      <el-checkbox v-if="isGetBook" size="small" v-model="getBook"
                   style="margin-left: 30px;margin-right: 0" label="平台代提"/>
      <el-tooltip v-if="isGetBook" placement="top-start" :enterable="false" effect="light">
        <div slot="content">
          如勾选，按供货商是否设置代提进行标记<br><br>
          不勾选，则生成的订单全部标记为自提
        </div>
        <i class="el-icon-info" style="color: #C0C4CC;margin-left: 5px;font-size: 12px"/>
      </el-tooltip>
      <el-checkbox size="small" v-model="hideOrder"
                   style="margin-left: 30px" label="屏蔽订单"/>
      <el-tooltip placement="top-start" :enterable="false" effect="light">
        <div slot="content">
          如勾选，则生成的订单全部标记为屏蔽<br><br>
          不勾选，按供货商是否设置屏蔽进行标记
        </div>
        <i class="el-icon-info" style="color: #C0C4CC;margin-left: 5px;font-size: 12px"/>
      </el-tooltip>
    </div>
    <el-pagination background layout="prev,pager,next" :page-size="50" :total="total"
                   :current-page="query.page" @current-change="pageChange"
                   :hide-on-single-page="true" style="margin-top: 0;margin-bottom: 25px"/>
    <div v-if="total>0" style="height: 23px;line-height: 13px">
      <span class="span" style="color: #606266">已选 </span>
      <span class="span" style="color: darkorange">{{this.$refs.multipleTable.selection.length}}</span>
      <span class="span" style="color: #606266"> 条 (可分页)</span>

      <span class="span" style="color: #606266;margin-left: 30px">搜索出 </span>
      <span class="span" style="color: #409EFF">{{total}}</span>
      <span class="span" style="color: #606266"> 条结果</span>
      <span class="span" style="color: #606266;margin-left: 30px">订数 </span>
      <span class="span" style="color: #409EFF">{{countnum}}</span>
      <span class="span" style="color: #606266;margin-left: 30px">书款 </span>
      <span class="span" style="color: #409EFF">{{parseFloat(countprice).toFixed(2)}}</span>
    </div>
    <el-table border stripe size="small" :data="carts" :row-style="{height:'42px'}"
              :header-cell-style="{background:'#ECF5FF',fontWeight: 'normal'}"
              style="width: 1000px" @select="pinSelect" ref="multipleTable"
              :row-key="getRowKeys">
      <el-table-column type="selection" :reserve-selection="true" width="40px" key="0"
                       header-align="center" align="center"/>
      <el-table-column label="书号" prop="product.book.isbn" width="110"
                       :resizable="false"/>
      <el-table-column label="书名" prop="product.book.title" width="274"
                       :resizable="false"/>
      <el-table-column label="定价" prop="product.book.price" width="60" :resizable="false"
                       :formatter="bookPriceFormat"/>
      <el-table-column label="供货商" width="200" :resizable="false">
        <template slot-scope="scope">
          <div style="float:left">
            <el-tag v-if="scope.row.product.seller.restdate!==0" type="warning"
                    style="margin-left: -11px">
              休息至 {{restFormat(scope.row.product.seller.restdate)}}
            </el-tag>
            <div>
              <span :style="{marginRight:'5px',color:(scope.row.product.seller.restdate!==0?'#C0C4CC':'#606266')}">
                {{scope.row.product.seller.name}}</span>
              <el-tag type="primary">{{getFormat(scope.row.product.seller.getmode)}}</el-tag>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="折扣" prop="product.discount" width="50" :resizable="false"
                       :formatter="discountFormat"/>
      <el-table-column label="售价" prop="price" width="60" :resizable="false"
                       :formatter="cartPriceFormat"/>
      <el-table-column label="库存" prop="product.num" width="50" :resizable="false"/>
      <el-table-column label="订数" width="50" :resizable="false">
        <template slot-scope="scope">
          <div>
            <el-popover placement="right" v-model="scope.row.visibleUpd">
              <div style="display: flex;justify-content: center;align-items: center">
                <el-input-number class="family1" v-model="scope.row.order" size="mini" :min="1"/>
                <el-button class="buttonPopo" size="mini" type="success" @click="updCart(scope.row)"
                           icon="el-icon-check" :loading="scope.row.loadingUpd"/>
              </div>
              <el-link slot="reference" class="link" @click="initNum(scope.row)" :underline="false"
                       :style="{color:(scope.row.product.num===undefined ||
                       scope.row.num>scope.row.product.num)?'#F56C6C':'#606266'}">
                {{scope.row.num}}
              </el-link>
            </el-popover>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="删除 / 重选" width="105" :resizable="false" header-align="center">
        <template slot-scope="scope">
          <div class="scope">
            <el-popover placement="right" v-model="scope.row.visibleDel">
              <div style="display: flex;justify-content: center;align-items: center">
                <span class="spanPopo">确定删除？</span>
                <el-button class="buttonPopo" size="mini" type="success"
                           @click="delCart(scope.row,scope.$index)"
                           icon="el-icon-check" :loading="scope.row.loadingDel"/>
              </div>
              <el-button slot="reference" class="buttonTable" size="mini" type="danger"
                         icon="el-icon-delete" :loading="scope.row.loadingDel"/>
            </el-popover>
            <el-button class="buttonTable" size="mini" type="primary"
                       icon="el-icon-search" @click="getProduct(scope.row)"/>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev,pager,next" :page-size="50" :total="total"
                   :current-page="query.page" @current-change="pageChange"
                   :hide-on-single-page="true"/>
    <el-dialog :visible.sync="visibleUpl" custom-class="upload" append-to-body
               :close-on-click-modal="false">
      <div style="display: flex;flex-direction: column;width: 250px">
        <span class="span">
          上传说明<el-button type="text" class="textbutton" @click="downloadExp">下载示例</el-button><br><br>
          ● 文件格式为 xls 或 xlsx<br><br>
          ● 文件大小不超过 5MB<br><br>
          ● 最多读取前 10000 行数据
        </span>
        <el-radio-group v-model="clear" size="small" style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
        font-size: 13px;margin: 30px 0 15px 0">
          <el-radio-button label="true">清空购物车</el-radio-button>
          <el-radio-button label="false">保留原数据</el-radio-button>
        </el-radio-group>
        <el-radio-group v-model="type" size="small" style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
        font-size: 13px;margin: 15px 0 30px 0">
          <el-radio-button label="discount">折扣优先</el-radio-button>
          <el-radio-button label="num">库存优先</el-radio-button>
          <el-radio-button label="seller">指定供货商</el-radio-button>
        </el-radio-group>
        <div v-if="type!=='seller'"
             style="display: flex;justify-content: start;align-items: center;margin: 0 0 30px 0">
          <span class="span" style="margin-right: 5px">库存不小于</span>
          <el-input-number class="family" v-model="num" size="mini" :min="1" :max="99"/>
          <span class="span" style="margin-left: 5px">本</span>
        </div>
        <div style="display: flex;justify-content: space-between">
          <el-upload :action="this.baseURL+'uploadCart'" :headers="{token: token}"
                     :data="{clear: clear,type: type,num: num}"
                     :before-upload="uploadBef" :on-success="uploadSuc" :on-error="uploadErr"
                     accept=".xls,.xlsx" :show-file-list="false">
            <el-button type="primary" icon="el-icon-folder" size="small"
                       :loading="loadingUpl">{{loadingUpl?'正在上传':'选择文件'}}
            </el-button>
          </el-upload>
          <el-button v-if="!loadingUpl" size="small" @click="closeUpload">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    created () {
      this.getBuyerGetInfo()
      this.getCartList()
    },
    mounted () {
      window.addEventListener('keydown', code => {
        if (code.keyCode === 16 && code.shiftKey) {
          this.pin = true
        }
      })
      window.addEventListener('keyup', code => {
        if (code.keyCode === 16) {
          this.pin = false
        }
      })
    },
    data () {
      return {
        query: {
          type: 'isbn',
          value: '',
          name: '',
          page: 1
        },
        carts: [],
        total: 0,
        countnum: 0,
        countprice: 0,
        token: window.localStorage.getItem('token'),
        clear: undefined,
        type: 'discount',
        num: 1,

        isGetBook: false,
        getBook: true,
        hideOrder: false,
        ignoreNum: false,
        onlyChecked: false,

        visibleClr: false,
        loadingClr: false,
        visibleAdd: false,
        loadingAdd: false,
        visibleUpl: false,
        loadingUpl: false,
        loadingDlo: false,

        clearCheck: undefined,
        sendCheck: undefined,

        origin: -1,
        pin: false
      }
    },
    methods: {
      bookPriceFormat (row) {
        return parseFloat(row.product.book.price).toFixed(2)
      },
      cartPriceFormat (row) {
        if (row.price !== undefined) {
          return parseFloat(row.price).toFixed(2)
        }
      },
      discountFormat (row) {
        if (row.product.discount !== undefined) {
          return parseFloat(row.product.discount).toFixed(2)
        }
      },
      getFormat (value) {
        if (value === '0') {
          return '自提'
        } else if (value === '1') {
          return '兴茂'
        } else if (value === '2') {
          return '代发'
        } else {
          return '书店宝'
        }
      },
      initNum (row) {
        row.order = row.num
      },
      typeChange () {
        this.query.value = ''
      },
      selectCart () {
        this.$refs.multipleTable.clearSelection()
        this.query.page = 1
        this.getCartList()
      },
      pageChange (page) {
        this.query.page = page
        this.getCartList()
      },

      async getCartList () {
        const loading = this.loading()

        try {
          const { data: res } = await this.$http.get('getCartList', { params: this.query })

          loading.close()

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.total = 0
            this.carts = []
            this.$msg.warning(res.msg)
            return
          }

          this.total = res.data.total
          this.countnum = res.data.count.num
          this.countprice = res.data.count.price
          this.carts = res.data.data
          this.carts = this.carts.map(item => {
            this.$set(item, 'order', 1)
            this.$set(item, 'visibleUpd', false)
            this.$set(item, 'loadingUpd', false)
            this.$set(item, 'visibleDel', false)
            this.$set(item, 'loadingDel', false)
            return item
          })
          this.carts.forEach((item, index) => {
            item.index = index
          })

          document.documentElement.scrollTop = document.body.scrollTop = 0
        } catch (e) {
          this.total = 0
          this.carts = []
          loading.close()
          this.$msg.error(this.netError)
        }
      },
      async updCart (row) {
        if (row.order === undefined) {
          this.$msg.warning('请输入订数！')
          return
        }
        this.$set(row, 'visibleUpd', false)
        this.$set(row, 'loadingUpd', true)

        try {
          const { data: res } = await this.$http.post('updCart',
            {
              bookId: row.product.book.id,
              sellerId: row.product.seller.id,
              num: row.order
            })

          if (!this.isLogin(res)) {
            this.$set(row, 'loadingUpd', false)
            return
          }

          this.$set(row, 'loadingUpd', false)
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
          } else {
            this.$set(row, 'num', row.order)
            this.$msg.success('修改成功！')
          }
        } catch (e) {
          this.$set(row, 'loadingUpd', false)
          this.$msg.error(this.netError)
        }
      },
      async delCart (row, idx) {
        this.$set(row, 'visibleDel', false)
        this.$set(row, 'loadingDel', true)

        try {
          const { data: res } = await this.$http.get('delCart',
            {
              params: {
                bookId: row.product.book.id,
                sellerId: row.product.seller.id
              }
            })

          if (!this.isLogin(res)) {
            this.$set(row, 'loadingDel', false)
            return
          }

          this.$set(row, 'loadingDel', false)
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
          } else {
            this.carts.splice(idx, 1)
            this.total--
            this.$msg.success('删除成功！')
          }
        } catch (e) {
          this.$set(row, 'loadingDel', false)
          this.$msg.error(this.netError)
        }
      },
      async clrCart () {
        if (this.clearCheck !== '1234') {
          this.$msg.warning('输入1234后才可清空！')
          return
        }
        this.visibleClr = false
        this.loadingClr = true

        try {
          const { data: res } = await this.$http.get('clrCart')

          if (!this.isLogin(res)) {
            this.loadingClr = false
            return
          }

          this.loadingClr = false
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
          } else {
            this.carts = []
            this.total = 0
            this.$msg.success('清空成功！')
          }
        } catch (e) {
          this.loadingClr = false
          this.$msg.error(this.netError)
        }
      },
      async addOrder () {
        if (this.sendCheck !== '1234') {
          this.$msg.warning('输入1234后才可生成！')
          return
        }
        this.visibleAdd = false

        if (this.onlyChecked && this.$refs.multipleTable.selection.length === 0) {
          this.$msg.warning('请选择要生成订单的书目！')
          return
        }
        if (!this.onlyChecked && this.$refs.multipleTable.selection.length > 0) {
          this.$msg.warning('已选择书目，请勾选仅生成选中！')
          return
        }

        this.loadingAdd = true

        const carts = []
        if (this.onlyChecked) {
          for (let i = 0; i < this.$refs.multipleTable.selection.length; i++) {
            carts.push({
              bookId: this.$refs.multipleTable.selection[i].product.book.id,
              sellerId: this.$refs.multipleTable.selection[i].product.seller.id
            })
          }
        }

        try {
          const { data: res } = await this.$http.post('addOrder', carts, {
            params: {
              getBook: this.getBook,
              hideOrder: this.hideOrder,
              ignoreNum: this.ignoreNum,
              onlyChecked: this.onlyChecked
            }
          })
          this.loadingAdd = false

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
            return
          }

          this.selectCart()
          this.$msg.success('生成成功！')
        } catch (e) {
          this.loadingAdd = false
          this.$msg.error(this.netError)
        }
      },

      openUpload () {
        this.visibleUpl = true
        this.clear = undefined
      },
      closeUpload () {
        this.visibleUpl = false
      },
      uploadBef (file) {
        if (this.clear === undefined) {
          this.$msg.warning('请选择是否清空购物车原数据！')
          return false
        }

        const type = file.name.substring(file.name.lastIndexOf('.') + 1)
        if (type !== 'xls' && type !== 'xlsx') {
          this.$msg.warning('文件格式需为 xls 或 xlsx！')
          return false
        }
        if (file.size > 5 * 1024 * 1024) {
          this.$msg.warning('文件大小不能超过 5MB！')
          return false
        }
        this.loadingUpl = true
      },
      uploadErr () {
        this.loadingUpl = false
        this.$msg.error(this.netError)
      },
      uploadSuc (res) {
        this.loadingUpl = false

        if (!this.isLogin(res)) {
          return
        }
        if (res.code !== 0) {
          this.$msg.warning(res.msg)
          return
        }

        this.visibleUpl = false
        this.$msg.success('上传成功！')

        if (res.data !== undefined && res.data.length > 0) {
          this.$router.push({
            name: 'error',
            params: { errors: res.data }
          })
        }
        this.query.value = ''
        this.query.page = 1
        this.getCartList()
      },
      getProduct (row) {
        sessionStorage.setItem('id', row.product.book.id)
        sessionStorage.setItem('mark', '图书：' + row.product.book.isbn + ' ▪ ' + row.product.book.title + ' ▪ ¥' + row.product.book.price)
        const route = this.$router.resolve({ name: 'product' })
        window.open(route.href, '_blank')
      },

      async downloadExp () {
        try {
          const res = await this.$http.get('downloadCartExp',
            { responseType: 'blob' })

          if (res.headers['content-type'] !== ('application/excel')) {
            const reader = new FileReader()
            reader.readAsText(res.data)
            reader.onload = e => {
              const data = JSON.parse(e.target.result)
              if (!this.isLogin(data)) {
                return
              }
              this.$msg.warning(data.msg)
            }
            return
          }

          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.download = '上传示例.xlsx'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
          this.$msg.success('下载成功！')
        } catch (e) {
          this.$msg.error(this.netError)
        }
      },
      async downloadCart () {
        this.loadingDlo = true
        try {
          const res = await this.$http.get('downloadCart',
            {
              responseType: 'blob',
              params: {
                type: this.query.type,
                value: this.query.value,
                name: this.query.name
              }
            })

          this.loadingDlo = false

          if (res.headers['content-type'] !== ('application/xlsx')) {
            const reader = new FileReader()
            reader.readAsText(res.data)
            reader.onload = e => {
              const data = JSON.parse(e.target.result)
              if (!this.isLogin(data)) {
                return
              }
              this.$msg.warning(data.msg)
            }
            return
          }

          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.download = '购物车.xlsx'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
          this.$msg.success('下载成功！')
        } catch (e) {
          this.loadingDlo = false
          this.$msg.error(this.netError)
        }
      },
      async getBuyerGetInfo () {
        try {
          const { data: res } = await this.$http.get('getBuyerGetInfo')

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
          } else {
            this.isGetBook = res.data.getBook
          }
        } catch (e) {
          this.$msg.error(this.netError)
        }
      },

      initClearCheck () {
        this.clearCheck = undefined
      },
      initSendCheck () {
        this.sendCheck = undefined
      },

      restFormat (time) {
        const date = new Date(time)
        const Y = date.getFullYear()
        let MM = date.getMonth() + 1
        MM = MM < 10 ? ('0' + MM) : MM
        let d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        let h = date.getHours()
        h = h < 10 ? ('0' + h) : h
        let m = date.getMinutes()
        m = m < 10 ? ('0' + m) : m
        return Y + '-' + MM + '-' + d + ' ' + h + ':' + m
      },

      pinSelect (item, index) {
        const data = this.$refs.multipleTable.tableData // 获取所以数据
        const origin = this.origin // 起点数 从-1开始
        const endIdx = index.index // 终点数
        if (this.pin && item.includes(data[origin])) { // 判断按住
          const sum = Math.abs(origin - endIdx) + 1// 这里记录终点
          const min = Math.min(origin, endIdx)// 这里记录起点
          let i = 0
          while (i < sum) {
            const index = min + i
            this.$refs.multipleTable.toggleRowSelection(data[index], true) // 通过ref打点调用toggleRowSelection方法，第二个必须为true
            i++
          }
        } else {
          this.origin = index.index // 没按住记录起点
        }
      },
      getRowKeys (row) {
        return row.product.book.id + '-' + row.product.seller.id
      }
    }
  }
</script>

<style lang="less" scoped>
  /deep/ .family input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .family1 input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  /deep/ .el-button {
    height: 32px !important;
    font-size: 13px;
    padding: 0 15px;
  }

  .scope {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /deep/ .buttonTable {
    width: 32px !important;
    height: 22px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 5px;
  }

  .span {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  .link {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  .el-input-number {
    width: 100px !important;
  }

  /deep/ .buttonPopo {
    width: 40px !important;
    height: 28px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 0 0 10px;
  }

  .spanPopo {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .el-dialog__header {
    display: none;
  }

  /deep/ .el-dialog__body {
    padding: 0;
  }

  /deep/ .upload {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    width: 350px;
    height: 466px;
  }

  /deep/ .textbutton {
    width: 52px;
    height: 13px !important;
    font-size: 13px;
    padding: 0;
    float: right;
  }

  /deep/ .el-table td,
  /deep/ .el-table th.is-leaf {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border th,
  /deep/ .el-table--border th.gutter:last-of-type {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border td,
  /deep/ .el-table--border th {
    border-right: 0.5px solid #EBEEF5 !important;
  }
</style>
